/* Product card styles */
.p-card {
  display: block;
  margin-bottom: 1em;
  box-shadow: $card-shadow;
  @media #{$media-sm} {
    margin-bottom: 2em;
  }
  .p-card-head {
    position: relative;
    text-align: center;
    overflow: hidden;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    img {
      width: 100%;
      -webkit-border-top-left-radius: 2px;
      -webkit-border-top-right-radius: 2px;
      -moz-border-radius-topleft: 2px;
      -moz-border-radius-topright: 2px;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
  .p-card-body {
    background-color: #FFF;
    border-top: 0;
    padding: 0.5em 1em 1em;
    color: $text;
    text-decoration: none;
    -webkit-border-bottom-right-radius: 2px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -moz-border-radius-bottomleft: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .p-card-foot {
    background-color: $orange;
    border: 1px solid $orange;
    color: #FFF;
    padding: 0.5em;
    text-align: center;
    margin-top: 1em;
    font-weight: 400;
  }
  h4 {
    margin: 0.2em 0 0.4em;
  }
  .provider {
    font-size: 0.875em;
    color: $text-lt;
    font-weight: 400;
    margin-bottom: 0em;
  }
}

.p-cards-wrapper {
  a.p-card {
    &:hover {
      .p-card-foot {
        background-color: #FFF;
        color: $orange;
      }
    }
  }
}

.p-feature-list {
  li {
    margin-bottom: 0.75em;
    font-size: 0.875em;
    font-weight: 400;
  }
}

.icon-wrapper {
  width: 30px;
  margin-right: 0.25em;
}
