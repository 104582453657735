.pills-select {
  button {
    font-size: 1em;
    border: 1px solid $text-lt;
    color: $text-lt;
    border-radius: 25px;
    height: 40px;
    padding: 10px 15px;
    background: white;
    line-height: 1;

    &:hover {
      border-color: $blue;
      color: $blue;
    }

    &:disabled {
      border-color: $input;
      color: $input;
    }
  }

  .pills-select-options {
    border: 1px solid $text-lt;
    z-index: 99999;
    position: absolute;
    background: white;
    padding: 15px;
    margin-top: 10px;
    margin-right: 15px;
    border-radius: 12px;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        label {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        &.selected {
          label {
            color: $blue;
          }
        }

        input {
          margin-right: 6px;
        }
      }
    }

    p {
      margin-top: 0;
      margin-left: 25px;
      font-size: 0.75em;
    }

    .clear-button {
      color: $blue;
      background-color: #FFF;
      cursor: pointer;
      font-size: .75em;
      padding: 15px 20px;
      border-radius: 4px;
      line-height: 1;
      border: 1px solid $blue;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $blue;
        color: #FFF;
      }
    }
  }

  &.has-value {
    button {
      border-color: $blue;
      color: $blue;
    }
  }
}
.pills-select-single {
  input[type=checkbox] {
    display: none;
  }
}
