footer {
  background-color: $text;
  flex-shrink: 0;
  padding: 50px 0 40px;
  @media #{$media-sm} {
    padding: 70px 0 50px;
  }
  .cols {
    display: grid;
  	display: -ms-grid;
  	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    @media #{$media-md} {
      grid-gap: 2em;
    	-ms-grid-gap: 2em;
		}
  }
	.col-1 {
		grid-column: span 12;
		-ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 1;-ms-grid-column-span: 2;grid-column: 1/3;
		}
  }
	.col-2 {
		grid-column: span 6;
		-ms-grid-column-span: 6;
    margin: 1em 0;
		@media #{$media-md} {
      -ms-grid-column: 3;-ms-grid-column-span: 2;grid-column: 3/5;
      margin: 0;
		}
	}
	.col-3 {
		grid-column: span 6;
		-ms-grid-column-span: 6;
    margin: 1em 0;
		@media #{$media-md} {
      -ms-grid-column: 5;-ms-grid-column-span: 2;grid-column: 5/7;
      margin: 0;
		}
	}
  .col-4 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 7;-ms-grid-column-span: 1;grid-column: 7/8;
		}
	}
  .col-5 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    margin-top: 1em;
		@media #{$media-md} {
      -ms-grid-column: 8;-ms-grid-column-span: 5;grid-column: 8/13;
		}
	}
  .cr {
    font-size: 10px;
    color: $blue-lt;
    margin: 2px 0;
  }
  h6 {
    text-transform: uppercase;
    color: #FFF;
  }
  ul {
    padding-left: 0;
  	list-style: none;
  }
  li {
    margin-bottom: 0.8em;
    a {
      color: $blue-lt;
      opacity: 0.5;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
      &:hover {
        color: #fff;
        opacity: 1;
      }
    }
  }
}
