/* utility classes for setting widths when using flexbox */
.w-30 {
  width: 30%;
  @media #{$media-md-down} {
    width: 100%;
  }
}

.w-40 {
  width: 40%;
  @media #{$media-md-down} {
    width: 100%;
  }
}

.w-50 {
  width: 48%;
  @media #{$media-xs} {
    width: 100%;
  }
}

.w-70 {
  width: 70%;
  @media #{$media-xs} {
    width: 100%;
  }
}

.w-80 {
  width: 80%;
  @media #{$media-xs} {
    width: 100%;
  }
}

.w-100 {
  width: 100%;
}

/* padding and margin utility classes */
.mb-0 {
  margin-bottom: 0px;
}

.mb-05 {
  margin-bottom: 0.5em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.mr-05 {
  margin-right: 0.5em;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.ml-1 {
  margin-left: 1em;
}

.ml-2 {
  margin-left: 2em;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}
