.dashboard {
  background-color: $blue-lt-bg;
  border-top: 1px solid $blue-lt;
  padding: 3em 0;
}

.dash-cols {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-md} {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
  }
  /* below are grid styles for 2 col layout */
  .dash-col-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      margin-right: 1em;
		}
  }
  .dash-col-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      margin-left: 1em;
		}
  }
}

.dash-3-cols {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-md} {
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
  }
  /* below are grid styles for 3 col layout */
  .dash-col-1-3 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    @media #{$media-md} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      margin-right: 1em;
    }
  }
  .dash-col-2-3 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    @media #{$media-md} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      margin-left: 1em;
      margin-right: 1em;
    }
  }
  .dash-col-3-3 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    @media #{$media-md} {
      -ms-grid-column: 3;-ms-grid-column-span: 1;grid-column: 3/4;
      margin-left: 1em;
    }
  }
}

/* below is grid styles for the four cards left and 1 card right layout used on Agent dashboard */
.dash-4-1 {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-md} {
    grid-template-columns: 1fr 1fr 2fr;
    -ms-grid-columns: 1fr 1fr 2fr;
    grid-template-rows: 2;
	   -ms-grid-rows: 2;
  }
  .dash-pos-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      -ms-grid-row: 1;-ms-grid-row-span: 1;grid-row: 1/2;
      margin-right: 1em;
		}
  }
  .dash-pos-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      -ms-grid-row: 1;-ms-grid-row-span: 1;grid-row: 1/2;
      margin-left: 1em;
      margin-right: 1em;
		}
  }
  .dash-pos-3 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      -ms-grid-row: 2;-ms-grid-row-span: 1;grid-row: 2/3;
      margin-right: 1em;
		}
  }
  .dash-pos-4 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      -ms-grid-row: 2;-ms-grid-row-span: 1;grid-row: 2/3;
      margin-left: 1em;
      margin-right: 1em;
		}
  }
  .dash-pos-5 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 3;-ms-grid-column-span: 2;grid-column: 3/5;
      -ms-grid-row: 1;-ms-grid-row-span: 2;grid-row: 1/3;
      margin-left: 1em;
		}
  }
}

.dash-card {
  margin-bottom: 2em;
}

.dash-card-head {
  background-color: $blue;
  color: #FFF;
  height: 60px;
  .heading {
    margin-left: 1em;
  }
  h3 {
    font-size: 1.25em;
    margin-bottom: 0;
  }
  .dash-card-btn {
    background-color: $orange;
    width: 60px;
    height: 100%;
    &:hover {
      background-color: $orange-lt;
    }
  }
}

.dash-card-body {
  background-color: #FFF;
  border: 1px solid $blue-lt;
  border-top: 0;
  padding: 1em;
  .number-lg {
    font-size: 1.25em;
    font-weight: 700;
  }
}

.sm-saved-prop {
  h5 {
    margin: 0.25em 0;
  }
}
