/*styles specific to Accommodation vertical*/
.cities-wrapper {
  justify-content: center;
}

.accomm-tabs {
  text-align: center;
  margin: 1em 0 2em;
  > button {
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 20px;
    @media #{$media-md} {
      margin: 0 0.5em;
    }
    &:hover {
      color: $blue;
    }
    &.selected-tab {
      background-color: $blue;
      border-radius: $border-radius;
      color: #FFF;
      padding: 15px 20px;
      border: 1px solid $blue;
      &:hover {
        background-color: #FFF;
        color: $blue;
      }
    }
    &:focus {
      outline: none;
    }
  }
}

.listing-sections-tabs {
  display: none;
  @media #{$media-md} {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #FFF;
    margin: 1em 0 0;
    z-index: 100;
  }
  a {
    display: block;
    background: none;
    border: none;
    font-size: 0.875em;
    font-weight: 500;
    margin-right: 1em;
    padding: 0.5em 0;
    color: $text;
    border-bottom: 3px solid transparent;
    white-space: nowrap;
    @media #{$media-md} {
      margin-right: 1.5em;
    }
    &.active {
      border-bottom: 3px solid $orange;
      font-weight: 600;
    }
  }
  @media #{$media-xs} {
    a:last-child {
      padding-right: 1em;
    }
  }
  &.stuck {
    background: $text;
    a {
      color: #FFF;
    }
  }
}

.a-city {
  @include display-flex;
  justify-content: center;
  align-items: center;
  max-width: 44%;
  margin: 0.5em;
  color: #FFF;
  text-shadow: 1px 1px 2px rgba(0,0,0,.4);
  &:hover {
    color: $blue-lt-bg;
  }
  img {
    border-radius: $border-radius;
  }
  @media #{$media-sm} {
    max-width: 310px;
    margin: 1em;
    img {
      @include hover-transition;
      &:hover {
        @include hover-scale
      }
    }
  }
  .city-name {
    position: absolute;
    z-index: 2;
    font-size: 20px;
    @media #{$media-sm} {
      font-size: 24px;
    }
  }
}

.filter-wrapper-mobile {
  @include display-flex;
  justify-content: space-between;
  background-color: #FFF;
  padding: 1em;
  a {
    color: $text;
  }
  @media #{$media-sm} {
    display: none;
  }
}

.filter-wrapper {
  padding: 1em;
  background-color: #FFF;
  border-radius: $border-radius;
  @media #{$media-sm} {
    padding: 1.4em 1.4em 0.2;
    margin: 1em 0 1.4em;
    box-shadow: $card-shadow;
  }
  a {
    text-decoration: none;
  }
}

.map-view-filters {
  .filter-wrapper {
    @media #{$media-sm} {
      padding: 0;
      margin: 1em 0 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.filters-row {
  @include display-flex;
  @media #{$media-sm} {
    .form-item {
      margin-right: 1em;
      margin-bottom: 0;
    }
  }
}

#accomm-sr {
  padding-top: 1em;
  background-color: $blue-lt-bg;
  h1 {
    margin-bottom: 0.5em;
    font-size: 1.5em;
  }
  .sub-filters {
    font-size: 14px;
    margin: 0.25em 0 0.5em;
    .vs__dropdown-toggle {
      min-width: 140px;
    }
  }
  .filter-wrapper {
    display: none;
    @media #{$media-sm} {
      display: block;
    }
  }
}

.accomm-cols {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-sm} {
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
  }
  @media #{$media-md} {
    grid-template-columns: 3fr 3fr 2fr;
    -ms-grid-columns: 3fr 3fr 2fr;
  }
  .col-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    @media #{$media-sm} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
		}
		@media #{$media-md} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
		}
  }
  .col-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    @media #{$media-sm} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 2/3;
		}
		@media #{$media-md} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
		}
  }
  .col-3 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    @media #{$media-md} {
      -ms-grid-column: 3;-ms-grid-column-span: 1;grid-column: 3/4;
		}
  }
}

.accom-listing-cols {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-xs} {
    grid-template-rows: auto 1fr;
    -ms-grid-rows: auto 1fr;
  }
  @media #{$media-sm} {
    grid-template-columns: 5fr 4fr;
    -ms-grid-columns: 5fr 4fr;
  }
  @media #{$media-md} {
    grid-template-columns: 6fr 4fr;
    -ms-grid-columns: 6fr 4fr;
  }
  .col-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    margin-bottom: 2em;
    @media #{$media-xs} {
      -ms-grid-row: 2;-ms-grid-row-span: 1;grid-row: 2/3;
    }
		@media #{$media-sm} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      margin-right: 2em;
		}
    @media #{$media-md} {
      margin-right: 3em;
		}
  }
  .col-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    @media #{$media-xs} {
      -ms-grid-row: 1;-ms-grid-row-span: 1;grid-row: 1/2;
    }
		@media #{$media-sm} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      margin-top: 1em;
		}
  }
}

.map-side {
  display: none;
  @media #{$media-md} {
    display: block;
    height: 200px;
  }

  .map-image {
    max-width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.address {
  font-size: 0.875em;
  font-style: normal;
  margin-bottom: 0.5em;
  word-break: break-word;
}

.card {
  display: block;
  margin-bottom: 1em;
  width: 100%;
  box-shadow: $card-shadow;
  @media #{$media-sm} {
    margin: 0 1em 1em 0;
    max-width: 350px;
  }
  @media #{$media-md} {
    margin: 0 2em 2em 0;
    max-width: 369px;
  }
  .card-head {
    position: relative;
    height: 220px;
    overflow: hidden;
    background-color: $blue;
    background-position: 50%;
    background-size: cover;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .prop-highlight-banner {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $blue;
    color: #FFF;
    text-align: center;
    font-size: 0.875em;
    padding: 0.25em;
  }
  .save-prop {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    button {
      background-color: transparent;
      border: none;
      padding: 1em;
      cursor: pointer;
    }
  }
  .delete-saved-prop {
    color: $text;
    font-weight: 400;
    padding: 3px 6px;
    background-color: rgba(250, 250, 250, 0.7);
    border-radius: $border-radius;
  }
  .card-body {
    background-color: #FFF;
    border-top: 0;
    padding: 1em;
    color: $text;
    text-decoration: none;
    -webkit-border-bottom-right-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    word-break: break-word;
    min-height: 290px;
    max-height: 290px;
    .address {
      height: 38px;
      overflow: hidden;
    }
  }
  .card-foot {
    background-color: $orange;
    border: 1px solid $orange;
    color: #FFF;
    font-weight: 400;
    padding: 0.5em;
    text-align: center;
    margin-top: 1em;
  }
  h4 {
    margin: 0.25em 0 0.5em;
  }
}

.fp-card {
  .card {
    @media #{$media-sm} {
      max-width: 30%;
      margin: 0 0.5em 2em;
    }
  }
}

.cards-wrapper {
  a.card {
    &:hover {
      .card-foot {
        background-color: #FFF;
        color: $orange;
      }
    }
  }
}

.prop-features {
  margin: 0.25em 0;
}

.listing-page-features {
  border-bottom: 1px solid $blue-lt;
  padding: 0 0 1.5em;

  div:last-child {
    margin-bottom: 0;
  }

  .prop-features {
    margin: 0 0 1.25em;
  }

  .a-feature {

    img {
      width: 24px;
      height: 24px;
      margin-right: 0.625em;
    }
  }

  .address {
    font-size: 1em;
    margin-right: 0.5em;
    margin-bottom: 0em;
  }
}

.a-feature {
  margin-right: 1.2em;
  font-size: 0.875em;
  &.last {
    margin-right: 0;
  }
}

.suburb {
  font-size: 0.875em;
  color: $text-lt;
  font-weight: 400;
  margin-bottom: 0em;
}

.price-fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 75px;
}

.prop-price-mobile {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px 0;
  background-color: #FFF;
  z-index: 10;
  font-size: 0.875em;
  .price-pw {
    font-size: 1.25em;
    color: $blue;
    font-weight: 600;
  }
  @media #{$media-sm} {
    display: none;
  }
}

.price-card {
  display: none;
  @media #{$media-sm} {
    display: block;
    margin: 1em 0;
    border-radius: $border-radius;
    border: 1px solid $blue-lt;
    background-color: $blue-lt-bg;
    color: $text;
    font-size: 0.875em;
  }
  @media #{$media-md} {
    margin: 1em 0 1em 1em;
  }
  .highlight {
    color: #FFF;
    font-weight: 600;
    padding: 1rem;
    background-color: $blue;
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: center;
  }
  .price-card-body {
    padding: 2em;
  }
}

.highlight-mob {
    color: #FFF;
    font-weight: 600;
    padding: 0.75rem;
    background-color: $blue;
    text-align: center;
    margin-bottom: 1em;
  @media #{$media-sm} {
    display: none;
  }
}

.price-pw {
  font-size: 1.75em;
  color: $blue;
  font-weight: 700;
}

address {
  font-family: 'Montserrat', sans-serif;
}

.main-prop-img-wrapper {
  position: relative;
}

.accomm-main-img {
  cursor: pointer;
}

.img-counts {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  z-index: 2;
}

.img-count-icon {
  position: relative;
  display: inline-block;
  margin: 0 0.4em 0.5em;

  img {
    width: 22px !important;
    height: auto;

    @media #{$media-sm} {
      width: 30px !important;
    }
  }
}

.photo-badge {
  position: absolute;
  top: -1px;
  right: -7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $green;
  color: $blue;
  text-align: center;
  line-height: 16px;
  font-weight: 700;
  font-size: 10px;
}

.accomm-thumbnails {
  margin-top: 0.5em;

  img {
    border-radius: $border-radius;
  }

  .a-thumb {
    cursor: pointer;
    width: 19%;
    margin-right: 0.5em;

    @media #{$media-sm} {
      width: 12%;
      margin-right: 0.75em;
    }

    @media #{$media-md} {
      width: 9%;
    }
    
    &.more-button {
      border: 1px solid black;
      border-radius: 4px;
      text-align: center;
      img {
        margin-bottom: 0.15em;
        @media #{$media-sm} {
          margin-bottom: 0.25em;
        }
      }
      .more-icon {
        @media #{$media-xs} {
          width: 22px;
          height: auto;
        }
      }
      .more-text {
        font-weight: 400;
        font-size: 0.75em;
        @media #{$media-sm} {
          font-size: 0.875em;
        }
      }
    }
  }
  .video-thumb {
    position: relative;
  }
  .thumb-play {
    position: absolute;

  }
}

.prop-icon-list {
  margin-bottom: 0;
  > li {
    width: 48%;
    margin-right: 2%;
    margin-bottom: 1.25em;
    font-size: 0.875em;
    .prop-features-icon {
      width: 20px;
      height: 20px;
      margin-right: 0.625em;
    }
  }
}

.property-map {
  height: 480px;
  position: relative;
  .distance-to-controls {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    padding: 0.5em;
    @media #{$media-sm} {
      top: 1em;
      right: 5%;
      padding: 0.875em;
    }
    h6 {
      @media #{$media-xs} {
        margin: 0 0 0.5em;
        font-size: 14px;
      }
    }
    .result {
      border: 1px solid black;
      border-radius: 4px;
      padding: 10px;
      height: 40px;
      margin-top: 0.5em;
    }
  }
  .dt-select {
    width: 240px;
    @media #{$media-sm} {
      width: 335px;
    }
  }
  .vs__dropdown-menu {
    max-width: 100%;
    max-height: 250px;
    overflow: scroll;
    overflow-x: hidden;
    li {
      padding-left: 0.5em;
    }
  }
}

.static-property-map {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center center;
  padding: 20px;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0.8;
}

.swiper-button-prev, .swiper-button-next {
  width: 60px;
  height: 32px;
  background-size: 60px 32px;
  margin-top: -80px;
  padding: 80px 0;
}

.swiper-button-prev {
  //background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23FFFFFF'%2F%3E%3C%2Fsvg%3E") !important;
  padding-right: 5px;
  left: 0;
  background-repeat: no-repeat;
  color: white;
}

.swiper-button-next {
  padding-left: 5px;
  right: 0;
  background-repeat: no-repeat;
  color: white;
}

.similar-props {
  .swiper-wrapper {
    max-height: 450px;
  }

  .swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: $green;
  }

  @media #{$media-xxs} {
    .card {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.leased {
  padding: 10px;
  background-color: $text;
  color: $green;
  text-align: center;
  margin-bottom: 1em;
}

.property-search-marker {
  background: white;
  border: 1px solid black;
  padding: 5px;
  &.is-active {
    background-color: #bbccff;
  }
  &.is-selected {
    background-color: #7799ff;
  }
  &:after {
    display: block;
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 0.625rem;
    height: 0.625rem;
    transform: translateX(-50%) rotate(45deg);
    border: inherit;
    border-width: 0 1px 1px 0;
    background: inherit;
    content: "";
    z-index: 1;
  }
}

.map-search-item {
  display: inline-block;
  &.is-active {
    background-color: #bbccff;
  }
  &.is-selected {
    background-color: #7799ff;
  }
}

.listing-media-dialog {
  .close-button {
    position: absolute;
    right: 1em;
    top: 1em;
    z-index: 1;
  }
  .listing-media-main {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
    bottom: 100px;
    .swiper-slide {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      .modal-video-wrapper {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }
      .video-frame {
        width: 100%;
        height: 235px;
        max-width: 620px;
      }
      @media only screen and (min-width:868px) {
        .video-frame {
          height: 349px;
        }
      }
    }
  }
  .listing-media-thumbs {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    .swiper-slide {
      background-size: cover;
      background-position: center;
      width: 90px;
      &.swiper-slide-active {
        border: 2px solid red;
      }
    }
  }
}

.modal-footer .modal-forgot-password-link {
  width: 100%;
}

.prop-features-section {
  border-bottom: 1px solid $blue-lt;
  margin: 1.5em 0 1.75em;
}

.prop-features-other {
  margin-top: 0;
  margin-bottom: 1.5em;
  font-size: 0.875em;
}

.extra-text-section {
  margin: 1.5em 0 0;
  padding-bottom: 1.5em;
  border-bottom: 1px solid $blue-lt;
}

.room-type-section {
  padding: 2em 0;

  @media #{$media-sm} {
    padding: 3em 0;
  }

  h4 {
    @media #{$media-xs} {
      margin-left: 15px;
    }
  }
}

.room-type-tabs {
  margin: 0.75em 0 1em;
  > button {
    background: none;
    border: none;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    margin: 10px;
    &.selected-tab {
      border-bottom: 2px solid $orange;
    }
    &:focus {
      outline: none;
    }
  }
}

.room-type-card {
  display: block;
  margin-bottom: 1em;
  width: 100%;
  box-shadow: $card-shadow;
  border-radius: $border-radius;
  background-color: #FFF;
}

.rtc-head {
  display: flex;
  padding: 1em;

  h5 {
    margin: 0.25em 0 0;
  }
}

button.rtc-img {
  background: transparent;
  border: none;
  padding: 0;
}

.rtc-img {
  width: 36%;

  @media #{$media-sm} {
    width: 30%;
  }

  .rtc-img-wrapper {
    position: relative;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: $border-radius;
  }
}

.rtc-content {
  margin-top: 0.25em;
  width: 59%;
  margin-left: 5%;

  @media #{$media-xs} {
    font-size: 14px;

    .product-read-more {
      margin-top: 0.5em;
    }

    .a-feature {
      margin-right: 0.3em;

      span {
        margin-right: 0.3em;
      }

      &::after { 
        content: "/";
      }

      .icon {
        display: none;
      }
    }
  }

  @media #{$media-sm} {
    margin-top: 0;
    width: 65%;
    margin-left: 4%;
  }

  @media #{$media-md} {
    padding-top: 0.5em;
    margin-left: 2%;
  }
}

.rtc-lease {
  padding: 0.5em 1em;
  border-top: 1px solid $blue-lt;
  font-size: 0.875em;
}

.lease-features {

  .a-feature {
    margin-bottom: 0.5em;

    @media #{$media-sm} {
      margin-bottom: 1.25em;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5em;
    }
  }
}

.lease-left {

  width: 55%;
  word-break: break-word;

  @media #{$media-xs} {
    font-size: 13px;
  }

  span {
    font-weight: 700;

    @media #{$media-xs} {
      font-size: 12px;
    }
  }

  @media #{$media-sm} {
    width: auto;
  }
}

.lease-end {
  flex-direction: column;
  justify-content: space-between;
  @media #{$media-sm} {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.lease-price-wrapper {
  margin-bottom: 0.25em;

  @media #{$media-sm} {
    margin-bottom: 0.5em;
  }

  @media #{$media-md} {
    margin-right: 2em;
    margin-bottom: 0;
  }

  .lease-price {
    font-size: 14px;
    font-weight: 700;
    color: $blue;
    margin-right: 0.25em;

    @media #{$media-sm} {
      font-size: 18px;
    }
  }
}

.room-type-desc {
  display: block;
  margin-bottom: 0.75em;

  @media #{$media-sm} {
    margin-bottom: 2em;
  }
}

.property-type-select {
  .vs__dropdown-menu {
    border-radius: 10px;
    margin-top: 8px;
    min-width: 300px;
    background: white;
    li {
      padding: 16px 10px;
      border-bottom: 1px solid #dbdde2;
      &:last-child {
        border-bottom: 0;
      }
      h5 {
        margin-bottom: 0;
        font-size: 0.875em;
        font-weight: 600;
      }
      div {
        font-size: 0.875em;
      }
    }
  }
}
