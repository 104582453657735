.review {
  @include blue-gradient;
  font-size: 1.25em;
  font-weight: 400;

  .swiper-container {
    padding-bottom: 10px;

    .swiper-pagination {
      position: relative;
      margin-top: 20px;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background:rgb(239, 239, 239);
      opacity: 1;
      margin: 10px;

      &.swiper-pagination-bullet-active {
        background: $green;
      }
    }
  }
}

.review-img {
  img {
    width: 82px;
    height: auto;
  }
}

.review-text {
  color: #FFF;
  margin: 1.25em 0;
  @media #{$media-md} {
    padding: 0 10%;
  }
  blockquote {
    margin: 0;
  }
}

.reviewer {
  color: $blue-dk;
}
