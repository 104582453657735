.vbt-autcomplete-list {
  background-color: #fff;
  border: 2px solid $blue;
  border-bottom: 1px solid $blue;
  border-radius: $border-radius;
  position: absolute;
  z-index: 100;
}

.students-name-search {

  .suggested-item {
    padding: 10px 12px;
    border-bottom: 1px solid $blue;
  }
}

.form-group {
  flex-direction: column;
  @media #{$media-sm} {
    flex-direction: row;
  }
}

.form-item {
  margin-bottom: 1.2em;
}

label {
  display: block;
  margin-bottom: 0.25em;
  font-weight: 700;
  font-size: 0.75em;
  &.label-nm {
    margin-bottom: 0em;
  }
}

input {
  font-family: 'Montserrat', sans-serif !important;
}

.input {
  border: 1px solid $input;
  border-radius: $border-radius;
  box-sizing: border-box;
  display: inline-block;
  font-size: 1em;
  padding: 1px 12px;
  width: 100%;
  height: 40px;
  font-family: 'Montserrat', sans-serif;
  &.textarea {
    border-radius: $border-radius;
    box-sizing: border-box;
    padding: 10px 12px;
    height: auto;
  }

  &.invalid-input {
    border-color: #ee3333;
  }
  &.invalid-input::placeholder {
    color: #ee3333;
  }
  &.cc-input {
    padding-top: 7px;
  }
}

input[type=search]{
   -moz-appearance: none;
   -webkit-appearance: none;
   appearance: none;
}

.accom-search {
  @include display-flex;
  border-radius: $border-radius;
  .search {
    width: 100%;
    @media #{$media-md} {
      width: 90%;
    }
  }
  .input {
    border: 2px solid $orange;
    height: 50px;
  }
  .suggested-item {
    padding: 10px 12px 10px 39px;
    
    h5 {
      margin-bottom: 0;
    }
  }
}

.suggestion-heading {
  align-items: center;
  display: flex;
  cursor: default;
  padding: 10px 12px;
  color: black;
  font-weight: 700;
  border-top: 1px solid $blue;
  > img {
    margin-right: 0.5em;
  }
}

.center-form {
  @include display-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.modal-body {
  .center-form {
    display: block;
  }
  form {
    margin-bottom: 0px;
  }
}

/* honeypot */
.special-field {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.sg-radio {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 1em;
  .sg-radio-label {
    margin-left: 0.5em;
    display: flex;
    align-items: center;
    img {
      margin-left: 10px;
      width: 30px;
      height: auto;
    }
  }
}

.input-group {
  display: flex;
}

/* datepicker */
.vdp-datepicker {
  &.input {
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    .input-group-prepend {
      box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-right: 6px;
      .calendar-icon {
        display:block;
        margin-top:12px;
        margin-right: 3px;
        width: 14px;
        height: 14px;
        background-image: url('../images/dp-calendar.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .form-control {
      box-sizing: border-box;
      display: block;
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .input-group-append {
      box-sizing: border-box;
      display: flex;
      margin-left: -1px;
    }
  }

  input.input {
    border: none;
    background: none;
    padding-top: 0;
    padding-bottom: 0;
  }

  .vdp-datepicker__calendar {
    height: 322px;
  }

  &.open-upwards {
    .vdp-datepicker__calendar {
      position: relative;
      top: -370px;
    }
  }
}

.vdp-datepicker__clear-button {
  position: absolute;
  top: 7px;
  right: 1px;
}

.v-18 {
  .v-select {
    min-width: 100%;
    @media #{$media-sm} {
      min-width: 18em;
    }
  }
}
.v-16 {
  .v-select {
    min-width: 100%;
    @media #{$media-sm} {
      min-width: 16em;
    }
  }
}
.v-10 {
  .v-select {
    min-width: 100%;
    @media #{$media-sm} {
      min-width: 10em;
    }
  }
  .vdp-datepicker {
    min-width: 100%;
    @media #{$media-sm} {
      max-width: 10em;
    }
  }
}
.map-search-bar {
  .v-10 {
    .vdp-datepicker {
      @media #{$media-sm} {
        max-width: none;
      }
    }
  }
}
.v-6-5 {
  .v-select {
    min-width: 40%;
    @media #{$media-sm} {
      min-width: 6.5em;
    }
  }
}
.vs__dropdown-toggle {
  min-height: 40px;
  background-color: #FFF;
  padding: 0 0.5em;
  border: 1px solid $input;
  border-radius: $border-radius;
  @include display-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif !important;
  input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: $text;
  }
}
.vs__selected-options {
  padding: 0;
}
.vs__selected {
  margin: 0;
  padding: 0 4px;
  border: 0;
}
.vs__search, .vs__search:focus {
  padding: 0 4px;
  margin: 0;
}
.vs__actions {
  padding: 0;
}
.vs__open-indicator {
  fill: $text;
}
.vs__dropdown-option--highlight {
  background: $blue;
}
.vs__dropdown-menu {
  width: 100%;
  li {
    width: 100%;
    white-space: normal;
  }
}

.nav-select {
  .vs__dropdown-toggle {
    min-width: 107px;
    border: 1px solid $blue-lt;
    min-height: 26px;
    max-height: 26px;
    padding: 0 6px;
  }
  .vs__actions {
    padding: 2px 0 0;
  }
}

.form-dash {
  margin: 0 0.5em;
}

.invalid-input-message {
  color: #ee3333;
}

.form-success {
  color: #229922;
}
.form-error {
  color: #ee3333;
  margin-bottom: 10px;
}

.typeahead-loading {
  width: 50px;
  height: 50px;
  background-image: url('../images/loading-icon.svg');
  background-size: contain;
}

/* For Vue Dropzone */
.dropzone .dz-preview {
  height: 200px;
}

.warning-well {
  background-color: orange;
  padding: 1em;
}
