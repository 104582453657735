.topbar {
  background-color: $blue-lt-bg;
  border-bottom: 1px solid $blue-lt;
  height: 40px;
  font-size: 14px;
  .container {
    height: 40px;
  }
  .currency {
    margin: 0 1.25em;
  }
  .cart-number {
    font-size: 13px;
    margin-right: 0.25em;
  }
}

.logo-wrapper {
  height: 35px;
  @media #{$media-sm} {
    height: 50px;
  }
}

.navbar {
  position: relative;
  height: 50px;
  display: flex;
  justify-content: center;
  z-index: 100;
  background-color: #FFF;
  box-shadow: $card-shadow;
  @media #{$media-sm} {
    height: 76px;
  }
  .nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

/*
 Default styles + Mobile first
 Offscreen menu style
*/
.main-menu {
  position: absolute;
  display: none;
  left: -325px;
  top: 0;
  height: 100%;
	/*overflow-y: scroll;
	overflow-x: visible;*/
	transition: left 0.3s ease, box-shadow 0.3s ease;
	z-index: 999;
}

.main-ul {
  margin: 0;
  padding: 1em 0 0;
  min-height: 100%;
  .vs__dropdown-toggle {
    border: none;
  }
}

.main-a {
  display: block;
  color: #FFF;
  text-decoration: none;
  font-weight: 400;
  &:hover, &:focus {
    background: $blue;
    color: #FFF;
  }
  @media #{$media-xs} {
    padding: 15px 0;
  }
}

.main-a.student-drop {
  .vs__dropdown-toggle {
    .vs__selected-options {
      .vs__search {
        background-image: url('../images/user.svg');
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 25px;
      }
    }
  }
}

.main-menu .menu-close {
  position: absolute;
  top: -25px;
  right: 0;
}

/*
 On small devices, allow it to toggle...
*/
/*
 :target for non-JavaScript
 [aria-expanded] will be used if/when JavaScript is added to improve interaction, though it's completely optional.
*/
.is-mobile-open #main-menu {
  display: block;
  left: 0;
  right: 0;
  outline: none;
  /*-moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  box-shadow: 3px 0 12px rgba(0,0,0,.25);*/
}

.is-mobile-open .menu-close {
  z-index: 1001;
}

.is-mobile-open ul {
  /*position: absolute;
  top: -40px;
  left: -15px;*/
  z-index: 1000;
  min-height: 400px;
}

/*
 We could us `.main-menu:target:after`, but
 it wouldn't be clickable.
*/
.is-mobile-open .backdrop {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  /*background: #000;
  background: rgba(0,0,0,.85);*/
  background: $blue;
  cursor: default;
}

/* Screens between 768
   and 975px
*/
@media screen and (max-width: 976px) and (min-width: 768px) {
  .main-a {
    padding: 15px;
  }
  .main-menu {
    padding: 15px;
    .menu-close {
      right: 30px;
      top: -10px;
    }
  }
}

/*
 Larger screen styling
 Horizontal menu
*/
@media (min-width: 975px) {
  .logo-wrapper {
    img {
      height: 50px;
    }
  }

  .menu-toggle,
  .main-menu .menu-close {
    display: none;
  }

  /* Undo positioning of off-canvas menu */
  .main-menu {
    position: relative;
		left: auto;
		top: auto;
		height: auto;
    display: block;
  }

  .main-ul {
    display: flex;
    align-items: center;
    /* Undo off-canvas styling */
    padding: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: auto;
    width: auto;
    background: none;
    .main-li {
      margin-left: 2em;
      &.btn-on-nav {
        margin: 0px 0px 0px 15px;
        @media #{$media-lg-down} {
          margin-right: 15px;
        }
      }
    }
  }
  .main-a {
    color: $text;
    border: 0 !important; /* Remove borders from off-canvas styling */
    &:hover, &:focus {
      color: $blue;
      background: none; /* Remove background from off-canvas styling */
    }
    &.router-link-active {
      color: $blue;
    }
  }
}
