.contact-grid {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-md} {
    grid-template-columns: 2fr 1fr;
    -ms-grid-columns: 2fr 1fr;
  }
  .contact-col-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      margin-right: 2em;
		}
  }
  .contact-col-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-md} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
		}
  }
}

.contact-address {
  text-emphasis: none;
  span {
    display: block;
  }
}
