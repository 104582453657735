/* styles for hero banner on home page */
.highlight, h1 strong {
  color: $blue-dk;
}

.highlight, h3 strong {
  color: $blue-dk;
}

.banner-home {
  position: relative;
  background-color: $blue;
  @include blue-gradient;
  height: auto;
  @media #{$media-sm} {
    height: 500px;
  }
  h3 {
    color: #FFF;
    margin-bottom: 0;
    font-size: 1.1em;
    @media #{$media-sm} {
      font-size: 2em;
    }

    > strong {
      color: #001C71;
    }
  }
  p {
    color: #FFF;
    font-weight: 400;
  }
  .oval-lg {
    display: none;
    @media #{$media-sm} {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      max-width: 40%;
    }
  }
  .oval {
    display: none;
    @media #{$media-sm} {
      display: block;
      position: absolute;
      top: 0;
      left: 40%;
      max-width: 30%;
    }
  }
  .banner-grid {
    z-index: 10;
    @include full-grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    height: auto;
    @media #{$media-sm} {
      height: 500px;
    }
    .banner-col-1 {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
    }
    .banner-col-2 {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      @media #{$media-sm} {
        margin-left: 2em;
      }
    }
  }
  .banner-content {
    padding: 1em 0;
    @media #{$media-sm} {
      padding: 0;
    }
    @media #{$media-md} {
      padding: 0;
    }
  }
  .banner-promo {
    margin: 1em 0;
    @media #{$media-md} {
      margin-top: 4em;
      margin-bottom: 0;
    }
  }
}

/* styles for hero banner on 4 landing pages */
.banner-landing {
  position: relative;
  background-color: $blue;
  @include blue-gradient;
  height: 500px;
  text-align: center;
  @media #{$media-sm} {
    text-align: left;
  }
  h1 {
    color: #FFF;
    margin-bottom: 0;
  }
  h3 {
    color: #FFF;
    margin-bottom: 0;
  }
  p {
    color: #FFF;
    font-weight: 400;
  }
  @media #{$media-xs} {
    .btn {
      display: block;
      width: 100%;
    }
  }
  .oval-lg {
    display: none;
    @media #{$media-sm} {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      max-width: 40%;
    }
  }
  .oval {
    display: none;
    @media #{$media-sm} {
      display: block;
      position: absolute;
      top: 0;
      left: 40%;
      max-width: 30%;
    }
  }
  .banner-grid {
    z-index: 10;
    height: 500px;
    @include full-grid;
    @include all-cols-grid;
    @media #{$media-sm} {
      grid-template-columns: 1fr 1fr;
      -ms-grid-columns: 1fr 1fr;
    }
    .banner-col-1 {
      grid-column: span 12;
      -ms-grid-column-span: 12;
  		@media #{$media-sm} {
        -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
  		}
    }
    .banner-col-2 {
      grid-column: span 12;
      -ms-grid-column-span: 12;
  		@media #{$media-sm} {
        -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
        margin-left: 2em;
  		}
    }
  }
  .banner-content {
    padding: 2em 0 1em;
    @media #{$media-sm} {
      padding: 0;
    }
    @media #{$media-md} {
      padding: 0 0 2em;
    }
  }
  .banner-promo {
    margin-top: 1em;
    @media #{$media-sm} {
      margin-top: 0;
    }
    @media #{$media-md} {
      margin-top: 4em;
    }
  }
}
/* ends */

#home-hero {
  .btn {
    margin-top: 1em;
  }
  .banner-promo {
    @media #{$media-xxs} {
      margin-top: 0;
    }
  }
}

/* Banner style for other sub page banners */
.banner-sub {
  background-color: $blue;
  @include blue-gradient;
  padding: 3em 0;
  h1 {
    color: $blue-dk;
    margin-bottom: 0;
  }
  h2 {
    color: #FFF;
    margin: 0.5em 0 0;
    font-size: 1.25em;
  }
}
