.cart-discount {
  margin: 1em 0;
  padding: 0.875em 0 1em;
  border-top: 1px solid $input;
  border-bottom: 1px solid $input;
  font-style: italic;
}
.cart-totals {
  width: 120px;
  margin-bottom: 0.875em;
}

.payment-totals {
  border-bottom: 1px solid $input;
  padding: 1em 0;
}

.del-cart-item {
  img {
    width: 10px;
    height: 10px;
  }
}

.cart-img {
  width: 12%;
  height: auto;
}

.cart-item-text {
  width: 72%;
}

.cart-item-price {
  width: 16%;
  word-break: break-word;
}

.essentials-img {
  width: 14%;
  height: auto;
}

.essentials-text {
  width: 73%;
}

.essentials-btn {
  width: 10%;
}

.cart-tab-wrapper {
  margin-bottom: 1em;
  border: 1px solid $blue-lt;
  width: 100%;
  @media #{$media-lg} {
    width: 100%;
  }
  @media #{$media-md} {
    width: 50%;
  }
}

.cart-tab-btn {
  padding: 1em 0.5em;
  font-size: 1em;
  text-align: center;
  color: $text-lt;
  text-transform: uppercase;
  background-color: #FFF;
  border: 0;
  border-bottom: 0;
  width: 50%;
}

.cart-tab-btn:disabled,
.cart-tab-btn[disabled]{
  border-bottom: 3px solid $blue;
  color: $text;
}

.modal-body .cart-tab-wrapper {
  width: 100%;
  .cart-tab-btn:disabled,
  .cart-tab-btn[disabled] {
    background-color: $blue-lt-bg;
  }
}
