.sign-box {
  display: block;
  margin-bottom: 1em;
  background-color: #FFF;
  border: 1px solid $blue-lt;
  padding: 1.5em 1em;
  width: 100%;
  @media #{$media-sm} {
    padding: 2em;
  }
  @media #{$media-md} {
    width: 50%;

    &.is-cart {
      width: 60%;
    }
  }
}
.modal-body {
  .sign-modal, .sign-box {
    width: 100%;
  }
}
