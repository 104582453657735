.oshc-calculator-banner {
    @include blue-gradient;
}

.h1-oshc {
    max-width: 530px;
    color: #FFF;
    margin-bottom: 1.5rem;
}

.calculator-wrapper {
    background-color: #FFF;
    border: 1px solid $blue-lt;
    border-radius: $border-radius;

    @media #{$media-md} {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
}

.calculator {
    padding: 1.5rem 1rem;

    @media #{$media-sm} {
        padding: 2rem 1.5rem;
    }

    @media #{$media-md} {
        padding: 2.5rem;
    }

    h2 {
        font-size: 1.2em;
        margin-bottom: 1.5rem;
    }
}

.calculator-img {
    display: none;

    @media #{$media-md} {
        display: block;
    }

    img {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.oshc-quote-details-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.oshc-table-wrapper-mobile {
    display: block;
    margin-top: 1.5rem;

    @media #{$media-sm} {
        display: none;
    }
}

.oshc-mobile-card {
    background-color: #FFF;
    border: 1px solid $blue-lt;
    border-radius: $border-radius;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.oshc-mobile-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem 0;
}

.oshc-mobile-card-footer {
    margin-top: 2rem;
    border-top: 1px solid $blue-lt;
    width: 100%;
    padding: 0.5rem 0.75rem;

    .btn-incl {
        background: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        width: 100%;
    }

    .btn-incl-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $blue;

        img {
            width: 10px;
            height: auto;
            transform: rotate(90deg);
        }
    }

    &.mobile-card-expanded {
        .btn-incl-content {
            img {
                transform: rotate(-90deg);
            }
        }
    }
}

.oshc-table-wrapper {
    display: none;
    margin-top: 1.5rem;
    border: 1px solid $blue-lt;
    border-radius: $border-radius;

    @media #{$media-sm} {
        display: block;
    }
}

.oshc-table {
    width: 100%;

    @media #{$media-sm} {
        border-radius: $border-radius;
        border-spacing: 0;
        background-color: #FFF;
    }

    td {
        text-align: center;
        padding: 0.375rem 0.25rem;

        @media #{$media-sm} {
            padding: 0.675rem 0.25rem;
        }

        img {
            vertical-align: middle;
        }
    }

    tbody tr {
        border-bottom: 1px solid $blue-lt;

        &:last-child {
            border-bottom: 0;

            td {
                padding-bottom: 1.5rem;
            }
        }

        &.tr-no-border {
            border-bottom: 0;

            td {
                padding: 0 0.25rem;
            }
        }
    }
}

.oshc-td-feature-text {
    font-size: 0.75rem;
    margin: 0.25rem 4% 0;
    font-weight: 400;
    line-height: 1.25;

    @media #{$media-sm} {
        margin: 0.375rem 12.5% 0;
    }
}

td.oshc-table-td-end {
    width: 50%;
    text-align: left;
    font-size: 0.875rem;

    @media #{$media-sm} {
        width: 34%;
        padding-right: 1rem;
        font-size: 1rem;
    }
}

.td-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info-icon {
    width: 16px;
    height: auto;
}

.info-icon-button {
    border: none;
    background: none;
}

.oshc-table-cost-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$media-sm} {
        padding: 1.5rem 0 0.5rem;
    }
}

.oshc-table-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.oshc-table-logo {
    width: auto;
    height: 32px;

    &.is-mb {
        height: 24px;
    }

    &.is-ahm {
        height: 44px;
    }
}

.oshc-table-cost {
    font-size: 2em;
    font-weight: 700;
    margin: 1rem 0;

    @media #{$media-sm} {
        margin: 0.5rem 0;
        font-size: 1.5em;
    }
}

.oshc-table-check {
    width: 24px;
    height: auto;
}

.oshc-table-feature-heading {
    margin-top: 1rem;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;
}