/* VARIABLES & MIXINS */
@import 'variables.scss';
/* IMPORTS */
@import "~vue-select/src/scss/vue-select.scss";
@import '~swiper/css/swiper.css';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import 'flex.scss';
@import 'nav.scss';
@import 'banners.scss';
@import 'reviews.scss';
@import 'spacing.scss';
@import 'buttons.scss';
@import 'blog.scss';
@import 'forms.scss';
@import 'pills-select.scss';
@import 'accomm.scss';
@import 'product-pages.scss';
@import 'product-cards.scss';
@import 'breadcrumb.scss';
@import 'footer.scss';
@import 'how-works.scss';
@import 'contact.scss';
@import 'cart.scss';
@import 'auth-screens.scss';
@import 'dashboard.scss';
@import 'notifications.scss';
@import 'dialogs.scss';
@import 'map-screens.scss';
@import 'pagination.scss';
@import 'tables.scss';
@import 'phone-countries.scss';
@import 'oshc.scss';
@import 'tooltip.scss';

body {
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: $text;
  line-height: 1.44;
  letter-spacing: 0.01em;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin: 0 0 15px;
  line-height: 1.2;
}

h1 { font-size: 2em; }
h2 { font-size: 2em; }
h3 { font-size: 2em; }
h4 { font-size: 1.2em; }
h5 { font-size: 1em; }
h6 { font-size: 1em; }

a {
  color: $blue;
  text-decoration: none;
  &:hover {
    color: $blue-dk;
  }
}

.small {
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.blue {
  color: $blue;
}

.white {
  color: #FFF;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.img-resp {
	width: 100%;
	height: auto;
}

.img-cover {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

section {
  padding: 3.5em 0;
  @media #{$media-sm} {
    padding: 5em 0;
  }
  &.below-banner-home {
    @media #{$media-xxs} {
      padding-top: 7em;
    }
  }
  &.below-banner {
    @media #{$media-xs} {
      padding-top: 16em;
    }
    @media #{$media-xxs} {
      padding-top: 12em;
    }
    @media #{$media-sm} {
      padding-top: 3.5em;
    }
    @media #{$media-md} {
      padding-top: 6.5em;
    }
  }
}

.section-heading {
  margin-bottom: 2em;
}

.blue-line {
  border-top: 2px solid $blue-lt;
}

.blue-bg {
  background-color: $blue-lt-bg;
}

.container {
  margin: 0 15px;
  position: relative;

  @media #{$media-sm} {
    margin: 0 auto;
    max-width: 1120px;
  	padding-left: 25px;
  	padding-right: 25px;
  	box-sizing: border-box;
  }

  &.mob-no-margin {

    @media #{$media-xs} {
      margin: 0;
    }
  }
}

.icon {
  margin-right: 0.5em;
}

.icon-ml {
  margin-left: 0.5em;
}

.icon-sm {
  width: auto;
  height: 14px;
}

.social-circle {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  background-color: #FFF;
  border: 2px solid $blue;
  margin-right: 0.5em;
  cursor: pointer;
  &:hover {
    border: 2px solid $green;
  }
  .icon {
    margin-right: 0;
  }
}

.we-chat-popup {
  position: relative;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
  padding: 5px;
  margin-left: -210px;
  margin-top:-270px;
  width:210px;
  height: 260px;
  text-align: center;

  > .instructions {
    height: 46px;
    font-weight: normal;
    margin: 0;
  }
}
@media all and (max-width: 991px) {
  .we-chat-popup {
    position: absolute;
    left: 10px;
    margin-left: 0;
  }
}

.verticals-wrapper {
  @include display-flex;
  flex-direction: column;
  @media #{$media-sm} {
    flex-direction: row;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: center;
  }
}

.vertical-btn {
  @include display-flex;
  align-items: center;
  margin: 1em 0;
  text-decoration: none;
  &:hover {
    background-color: $blue-lt-bg;
  }
  @media #{$media-sm} {
    padding: 1em;
    flex-direction: column;
    margin: 0 3%;
  }
}

.vert-icon {
  @media #{$media-sm} {
    img {
      height: 80px;
    }
  }
}

.vert-label {
  @include display-flex;
  justify-content: space-between;
  font-weight: 400;
  color: $blue-dk;
  width: 100%;
  margin-left: 2em;
  &:hover {
    color: $blue;
  }
  @media #{$media-sm} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0em;
    .vl-text {
      margin: 1em 0;
    }
  }
}

.features-wrapper {
  @include display-flex;
  flex-direction: column;
  @media #{$media-sm} {
    flex-direction: row;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: center;
  }
}

.feature {
  @include display-flex;
  align-items: center;
  margin: 1em 0 0;
  @media #{$media-sm} {
    flex-direction: column;
    margin: 1em 2% 0;
    width: 25%;
    min-width: 176px;
  }
}

.feature-icon {
  margin-right: 1em;
  img {
    width: 90px;
  }
  @media #{$media-sm} {
    margin-right: 0;
    img {
      width: auto;
      height: 150px;
    }
  }
}

.feature-text {
  margin-top: 1.25em;
  p {
    margin-top: 0.25em;
  }
  @media #{$media-sm} {
    @include display-flex;
    flex-direction: column;
    text-align: center;
  }
}

.subpage-img {
  img {
    border-radius: $border-radius;
    box-shadow: $card-shadow;
  }
}

.supplier-wrapper {
  .feature {
    @media #{$media-sm} {
      margin: 2em 2% 0;
      width: 42%;
    }
  }
  .supp-icon {
    margin-right: 1em;
    img {
      width: 60px;
    }
    @media #{$media-sm} {
      margin-right: 0;
      img {
        width: 80px;
        height: auto;
      }
    }
  }
  h4 {
    font-size: 1em;
  }
}

.for-mobile {
  @media #{$media-sm} {
    display: none;
  }
}

.not-for-mobile {
  @media #{$media-xs} {
    display: none;
  }
}

.back-wrapper {
  padding: 1em;
  background-color: #FFF;
  .btn-link {
    color: $text;
  }
}

.subpage-grid {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-sm} {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 3fr;
    -ms-grid-columns: 1fr 3fr;
  }
  .col-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    margin-bottom: 2em;
		@media #{$media-sm} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      margin-right: 2em;
		}
    @media #{$media-md} {
      margin-right: 3em;
		}
  }
  .col-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-sm} {
      -ms-grid-column: 2;-ms-grid-column-span: 3;grid-column: 2/5;
      margin-top: 1em;
		}
  }
}

.faq-sidebar {
  border-radius: $border-radius;
  padding: 1em 0;
  border: 1px solid $blue;
}

.faq-sidebar-btn {
  display: block;
  width: 100%;
  font-size: 1em;
  text-align: left;
  border: 0;
  background-color: #FFF;
  padding: 1em;
  color: $blue;
  cursor: pointer;
  &:hover, &.active {
    @include blue-gradient;
    color: #FFF;
  }
}

.faq-q {
  cursor: pointer;

  .anchor-link {
    opacity: 0;
    transition: opacity .3s;
    line-height: 1;
    margin-left: -20px;
    padding-right: 4px;
  }
  &:hover {
    .anchor-link {
      opacity: .5;
      transition: none;
    }
  }
}

.oshc-partner-logo-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  @media #{$media-xs} {
    margin-top: 1.5rem;
  }
}

.partner-logo {
  width: 160px;
  height: auto;

  @media #{$media-sm} {
    width: 220px;
  }
}
