.bread-bg {
  background-color: $text;
}

.breadcrumb {
  padding: 0.875em 0;
  font-size: 12px;
  color: $blue-lt-bg;
  word-break: break-word;
  a {
    color: $blue-lt-bg;
    text-decoration: none;
    &:hover {
      color: #FFF;
      text-decoration: underline;
    }
  }
  .icon {
    height: 8px;
    margin: 0 0.4em 0 0.15em;
  }
}
