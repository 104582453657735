.notification-messages {
  position: fixed;
  bottom: 10px;
  left: 10px;
  max-width: 400px;
  z-index: 99999;

  .notification-message {
    width: 100%;
    padding: 10px;
    color: $blue-dk;
    font-weight: 400;
    font-size: 20px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin: 5px;

    &.type-success {
      background: $green;
    }

    &.type-error {
      background: $red;
    }

    .btn.btn-link {
      display: inline-block;
      padding: 10px;
      color: white;
      font-weight: bold;
    }

    &.fade-enter-active {
      transition: all 0.5s ease;
    }

    &.notification-message.fade-leave-active {
      transition: opacity .1s;
    }

    &.fade-enter,
    &.notification-message.fade-leave-to {
      opacity: 0;
    }
  }
}
