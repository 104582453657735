.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity .3s ease;

  .modal-backdrop {
    cursor: default;
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0, 0, 0, .5);
  }

  .modal-wrapper {
    @include display-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    &.modal-full-screen {
      height: auto;

      .modal-container {
        width: 100%;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        max-width: 100%;
        padding: 0;
        overflow-y: scroll;

        .modal-body {
          margin: 0;
          width:100%;
          height:100%;
        }
      }
    }
  }

  .modal-container {
    position: relative;
    min-width: 280px;
    max-width: 80%;
    max-height: 80%;
    overflow: hidden;
    z-index: 110;
    backface-visibility: hidden;
    pointer-events: auto;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    &.dark {
      background: #000;
    }
    .modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      h4 {
        margin-top: 0;
      }
    }

    .modal-body {
      margin: 1em 0 1.25em;
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      /*> * {
        margin-left: 15px;
      }*/
    }
  }
  .modal-close {
    position: absolute;
    top: 1em;
    right: 1em;
    img {
      width: 1em;
      height: 1em;
    }
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

body.full-screen-modal-open {
  overflow: hidden;
}

.qr-code {
  width: 100%;
	height: auto;
  @media #{$media-sm} {
    width: 300px;
  }
}
