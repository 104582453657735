/* utility classes for flexbox */
.flex {
  @include display-flex;
}

.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-sa {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-end-bottom {
  -ms-flex-line-pack: end;
  align-items: flex-end;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-jc {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-ac {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-ac-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-start-horizontal {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
