.paginate-row {
  @media #{$media-md} {
    width: 75%;
    padding-right: 3em;
  }
}

.paginate-btn {
  width: 34px;
  height: 34px;
  border: 1px solid $blue-lt;
  border-radius: $border-radius;
  .icon {
    margin-right: 0;
  }
  &.next {
    border: 1px solid $blue;
  }
  &.left {
    margin-left: 1em;
  }
  &.right {
    margin-right: 1em;
  }
}

.paginate-numb {
  color: $blue-lt;
  padding: 0 0.5em;
  &.next {
    color: $blue;
  }
}
