$text: #0E1837;
$text-lt: #868C9E;
$input: lighten($text-lt,30%);
$blue: #009ADE;
$blue-lt: #E1F3F9;
$blue-lt-bg: #F9FCFD;
$blue-dk: #001C71;
$orange: #FF3F0F;
$orange-lt: lighten($orange,5%);
$green: #0FFFAE;
$red: #F05A5A;

$media-xxs: '(max-width:500px)';
$media-xs: '(max-width:767px)';
$media-sm: '(min-width:768px)';
$media-md: '(min-width:992px)';
$media-md-down: '(max-width:992px)';
$media-tab-down: '(max-width:1024px)';
$media-lg: '(min-width:1200px)';
$media-lg-down: '(max-width:1199px)';

$border-radius: 4px;
$card-shadow: rgba(0,28,113,0.1) 0px 1px 2px 0px;

@mixin full-grid {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

@mixin all-cols-grid {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
}

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin blue-gradient {
  background-image: linear-gradient(213deg, #0FFFAE 0%, #01AEFF 60%);
}

@mixin hover-transition {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -moz-transition: -moz-transform 0.2s ease-in-out;
  -ms-transition: -ms-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}

@mixin hover-scale {
  -webkit-transform: scale(1.02);
	-moz-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
}
