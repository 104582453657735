table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

.table-border {
  border: 1px solid $blue-lt;
  th {
    border: 1px solid $blue-lt;
    padding: .6em;
  }
  td {
    border: 1px solid $blue-lt;
    padding: .6em;
    vertical-align: top;
  }
  thead {
    background-color: $blue-lt;
    th {
      vertical-align: bottom;
      border-bottom: 2px solid $blue-lt;
    }
  }
}

.sg-table {
  width: 100%;
  margin-bottom: 1em;
  font-size: 14px;
  .btn-link {
    color: $orange;
    &:hover {
      color: $orange-lt;
    }
  }
}

.table-resp {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
