/*.how-grid {
  @include full-grid;
  @media #{$media-sm} {
    grid-template-columns: 10fr 9fr;
    -ms-grid-columns: 10fr 9fr;
  }
  .how-col-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    margin-bottom: 1em;
    @media #{$media-sm} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      margin-right: 2em;
		}
  }
  .how-col-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    margin-bottom: 1em;
    @media #{$media-sm} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      margin-top: 1.5em;
		}
		@media #{$media-md} {
      margin-top: 3em;
		}
  }
  p {
    margin-top: 0.25em;
  }
}*/

/* How it works uses the same layout as the
   .feature-wrapper on the homepage except
   the css below allows for 4 cols
*/
.how-wrapper {
  .feature {
    @media #{$media-sm} {
      margin: 1em 1% 0;
      width: 23%;
    }
  }
}
