button {
  font-family: 'Montserrat', sans-serif !important;
}

.btn {
  border-radius: $border-radius;
  padding: 15px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  line-height: 16px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  &.btn-cta {
    @media #{$media-sm} {
      min-width: 240px;
    }
  }
  &.btn-small {
    padding: 8px 12px;
    line-height: 22px;
  }
  &[disabled=disabled] {
    opacity: .75;
    cursor: default;
  }
}

.btn-orange {
  background-color: $orange;
  border: 1px solid $orange;
  color: #FFF;
  &:hover:not([disabled=disabled]) {
    background-color: #FFF;
    color: $orange;
  }
}

.btn-white {
  background-color: #FFF;
  border: 1px solid $text;
  color: $text;
  &:hover:not([disabled=disabled]) {
    color: $text-lt;
    border: 1px solid $text-lt;
  }
}

.save-later-btn {
  background-color: #FFF;
  border: 1px solid $green;
  color: $green;
  &:hover:not([disabled=disabled]) {
    background-color: $green;
    color: #FFF;
  }
}

.form-btn {
  border-radius: $border-radius;
  @include display-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  line-height: 16px;
  height: 40px;
  padding: 0 15px;
  &.mob-block {
    display: block;
    width: 100%;
  }
}

.nav-btn {
  color: $text;
  border: 1px solid $blue-lt;
  height: 26px;
  padding: 0 6px;
  background-color: #FFF;
  border-radius: $border-radius;
}

.btn-link {
  @include display-flex;
  align-items: center;
  background: transparent;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
  span {
    margin-left: 0.5em;
  }
  &.orange {
    color: $orange;
  }
  .icon {
    height: 16px;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

button, a[role="button"] {
  cursor: pointer;
}

.btn-more-options {
  background-color: #FFF;
  border: none;
  color: $blue;
  font-size: 0.875em;
  
  &:hover {
    color: $blue;
  }
}
