.map-pop {
  font-size: 14px;
  color: $text;
  &:hover {
    color: $blue;
  }
}

.map-pop-text {
  margin-left: 0.5em;
  word-break: break-word;
  font-size: 13px;
  line-height: 14px;
}

.map-sidebar {
  box-shadow: $card-shadow;
  position: absolute;
  left: 0;
  top:0px;
  bottom:0;
  width: 22%;
  overflow-y:auto;
  @media #{$media-tab-down} {
    width: 12%;
    .blue-line {
      display: none;
    }
  }
}

.map-search-bar {
  margin-left: 22%;
  @media #{$media-tab-down} {
    margin-left: 12%;
  }
}

.map-view-filters {
  margin-left: 2em;
}

.map-search-item {
  background-color: #FFF;
  border-bottom: 1px solid $blue-lt;
  &:hover {
    background-color: $blue-lt;
  }
}

.map-search-item.is-active {
  background-color: $blue-lt-bg;
}
.map-search-item.is-selected {
  background-color: $blue-lt-bg;
}

.map-dialog {
  color: $text;
  margin: 0 1em;
  padding: 1em 0;
  &:hover {
    cursor: pointer;
  }
}

.map-dialog-text {
  padding: 0.25em 1em 0;
  word-break: break-word;
  h4 {
    font-size: 1em;
    margin: 0.25em 0 0.5em;
  }
}

.mapboxgl-popup-close-button {
  font-size: 16px;
  color: $text-lt;
}

.map-prop-number {
  padding: 1em;
  h5 {
    margin-bottom: 0;
  }
  span {
    @media #{$media-tab-down} {
      display: none;
    }
  }
}

.modal-container .property-map {
  height: 100%;
}

.mgl-map-wrapper {
  width: 100%;
  height: 100%;
}
.search-map-dialog-map {
  margin-left: 22%;
}

.mapboxgl-map {
  font: 12px/20px 'Montserrat', sans-serif !important;
  width: 100%;
  height: 100%;
}

.modal-wrapper {
  &.modal-full-screen {
    .mapboxgl-map {
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
    }
  }
}