/* styles for smaller post cards shown on home page and landing pages */
.posts-wrapper {
  @include display-flex;
  justify-content: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}

.post-card {
  @include display-flex;
  min-height: 110px;
  margin: 0 2% 2em;
  width: 100%;
  border: 1px solid $blue-lt;
  background-color: #FFF;
  border-radius: $border-radius;
  @media #{$media-sm} {
    min-height: 150px;
    width: 47%;
    margin: 0 1.5% 2em;
    min-width: 400px;
  }
}

.post-card-img {
  font-size: 0;
  text-indent: -1000px;
  width: 48%;
  background-color: $blue;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  @media #{$media-sm} {
    width: 58%;
  }
}

.post-card-title {
  width: 52%;
  @include display-flex;
  align-items: center;
  padding: 0.875em;
  @media #{$media-sm} {
    width: 42%;
    padding: 1em 1.5em;
  }
  h4 {
    margin: 0;
  }
  @media #{$media-xs} {
    h4 {
      font-size: 0.875em;
    }
  }
  a {
    text-decoration: none;
    color: $text;
    &:hover {
      color: $blue;
    }
  }
}

/* styles for large post cards shown on blog index page */
.blog-index-card {
  border-radius: $border-radius;
  margin-bottom: 2em;
  width: 100%;
  border: 1px solid $blue-lt;
  background-color: #FFF;
  @media #{$media-sm} {
    width: 48%;
  }
}

.index-card-title {
  @include display-flex;
  flex-direction: column;
  padding: 1em;
  h3 {
    margin: 0;
    font-size: 1.5em;
    a { /* stylelint-disable-line no-descending-specificity */
      text-decoration: none;
      color: $text;
      &:hover {
        color: $blue;
      }
    }
  }
  @media #{$media-sm} {
    padding: 1.5em;
  }
}

.tags {
  font-size: 0.875em;
  margin-top: 0.5em;
  ul {
    margin: 0;
  }
  li {
    margin-right: 0.5em;
  }
}
.tag-title {
  margin-right: 0.5em;
  font-weight: 400;
}
.tag {
  color: $blue-dk;
  border-radius: $border-radius;
  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}

.index-card-img {
  display: block;
  width: 100%;
  height: 190px;
  background-color: $blue;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  @media #{$media-sm} {
    height: 280px;
  }
}

/* styles for post page */
.post {
  margin-bottom: 2em;
  width: 100%;
  border: 1px solid $blue-lt;
  background-color: #FFF;
  border-radius: $border-radius;
  @media #{$media-md} {
    width: 80%;
  }
}

.post-img {
  img {
    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.post-content {
  padding: 1em;
  @media #{$media-sm} {
    padding: 2em;
  }
  p {
      img {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: auto;
        margin-bottom: 25px;
        @media #{$media-sm} {
          width: 80%;
          margin-top: 25px;
          margin-bottom: 30px;
        }
      }
  }
}

.post-date {
  font-size: 14px;
  font-weight: 400;
}
