.upper-product-section {
  padding: 1em 0 2em 0;
  @media #{$media-sm} {
    padding: 4em 0 0 0;
  }
}

.lower-product-section {
  padding: 0;
}

.product-cols {
  @include full-grid;
  @include all-cols-grid;
  @media #{$media-sm} {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 4fr 4fr;
    -ms-grid-columns: 4fr 4fr;
  }
  .col-1 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
    margin-bottom: 2em;
		@media #{$media-sm} {
      -ms-grid-column: 1;-ms-grid-column-span: 1;grid-column: 1/2;
      margin-right: 2em;
		}
    @media #{$media-md} {
      margin-right: 3em;
		}
  }
  .col-2 {
    grid-column: span 12;
    -ms-grid-column-span: 12;
		@media #{$media-sm} {
      -ms-grid-column: 2;-ms-grid-column-span: 1;grid-column: 2/3;
      margin-top: 1em;
		}
  }
}

.product-img {
  border-radius: $border-radius;
}

.product-features {
  border-top: 1px solid $blue-lt;
  border-bottom: 1px solid $blue-lt;
  padding: 0.875em 0;
  margin-top: 1em;
}

.product-btn-row {
  margin: 1.5em 0 2em;
}

/* Some hacky styling here to allow us to use html truncate vue component */
.product-read-more {
  margin-top: 1em;
  > div {
    > button {
      /*margin-top: 0.5em;
      float:right;*/
      font-size: 14px;
      font-weight: 400;
      color: $text;
      font-family: 'Montserrat', sans-serif;
      background: none;
      border: none;
      padding-left: 0;
    }
  }
}

.health-cover-first-person-details {
  font-style: italic;
  margin-bottom: 20px;
}